import React from 'react'
import Helmet from 'react-helmet'
import { graphql, Link, PageProps } from 'gatsby'
import {
  GatsbyImage,
  StaticImage,
  getImage,
  ImageDataLike,
  IGatsbyImageData
} from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import parse from 'html-react-parser'

import Layout from '../layouts'
import HighlightedLink from '../components/HighlightedLink'
import PostLink from '../components/PostLink'
import BlogPost from '../interfaces/BlogPost'

import * as styles from './styles.module.css'

const DesktopGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: fit-content(100%);
  align-items: center;
  max-width: 1200px;
  margin: auto;

  @media only screen and (max-width: 640px) {
    display: none;
  }
`

const MobileGrid = styled.div`
  display: none;
  @media only screen and (max-width: 640px) {
    display: block;
  }
`

const MobileLineBreak = styled.br`
  display: none;
  @media only screen and (max-width: 640px) {
    display: block;
  }
`

const Line = styled.line`
  stroke: #92d2fc;
  stroke-width: 10;
  @media only screen and (max-width: 640px) {
    stroke-width: 7;
  }
`

interface SvgLineProps {
  length: number
}

const SvgLine: React.FC<SvgLineProps> = ({ length }) => (
  <div style={{ marginTop: '-4px', marginBottom: '16px' }}>
    <svg height="15" width="200">
      <Line x1="0" y1="0" x2={length} y2="0" />
    </svg>
  </div>
)

interface IndexSectionTextProps {
  title: string
  text: string
}

const IndexSectionText: React.FC<IndexSectionTextProps> = ({ title, text }) => (
  <div className={styles.indexSectionWrapper}>
    <h3 className={styles.indexSectionTitle}>{title}</h3>
    <SvgLine length={60} />
    {text.split(/\n\s*\n/).map((line, index) => (
      <p key={index} className={styles.indexSectionBodyText}>
        {line}
      </p>
    ))}
  </div>
)

const titleToKey = (s: string) => s.toLowerCase().replace(/\s/g, '-')
const colStyle = { minHeight: '450px' }

interface IndexSectionProps {
  title: string
  text: string
  imageTitle: string
  image?: IGatsbyImageData
  isRight: boolean
}

const IndexSection: React.FC<IndexSectionProps> = ({
  title,
  text,
  imageTitle,
  image,
  isRight
}) => {
  if (image == null) {
    throw Error(`Image for section ${title} is missing.`)
  }
  return isRight ? (
    <>
      <div style={{ gridColumn: '1 / 3', ...colStyle }}>
        <IndexSectionText title={title} text={text} />
      </div>
      <div style={{ gridColumn: '3 / 6', ...colStyle }}>
        <div className={styles.imageWrapper}>
          <GatsbyImage
            alt={imageTitle}
            image={image}
            className={styles.indexSectionImage}
            key={titleToKey(imageTitle)}
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <div style={{ gridColumn: '1 / 4', ...colStyle }}>
        <div className={styles.imageWrapper}>
          <GatsbyImage
            alt={imageTitle}
            image={image}
            className={styles.indexSectionImage}
            key={titleToKey(imageTitle)}
          />
        </div>
      </div>
      <div style={{ gridColumn: '4 / 6', ...colStyle }}>
        <IndexSectionText title={title} text={text} />
      </div>
    </>
  )
}

// interface IndexSectionMobileProps {
//   title: string
//   text: string
//   imageTitle: string
//   image?: IGatsbyImageData
// }

// const IndexSectionMobile = ({
//   title,
//   text,
//   imageTitle,
//   image
// }: IndexSectionMobileProps) => {
//   if (image == null) {
//     throw Error(`Image for section ${title} is missing.`)
//   }
//   return (
//     <>
//       <div className={styles.imageWrapper}>
//         <GatsbyImage
//           alt={imageTitle}
//           image={image}
//           className={styles.indexSectionImage}
//           key={`${titleToKey(imageTitle)}-mobile`}
//         />
//       </div>
//       <IndexSectionText title={title} text={text} />
//     </>
//   )
// }

const IntroText = styled.h2`
  font-size: 28px;
  font-weight: 300;
  line-height: 50px;
  margin-bottom: 0;
  font-style: italic;
  @media only screen and (max-width: 640px) {
    margin-top: 80px;
    font-size: 22px;
    line-height: 32px;
  }
`

const BlogLinkWrapper = styled.p`
  font-family: Charter, Georgia, 'Times New Roman', Times, serif;
  font-size: 24px;
  text-align: right;
  margin: 0 0 60px;
  @media (max-width: 640px) {
    font-size: 20px;
    text-align: left;
  }
`

// Using custom hook to get window size, since using react-responsive
// led to some layout shift issues.
// const useWidth = (): number => {
//   const hasWindow = typeof window !== 'undefined'
//   const [width, setWidth] = useState<number>(
//     hasWindow ? window.innerWidth : 1200
//   )

//   const updateWidth = () => {
//     setWidth(window.innerWidth)
//   }

//   useEffect(() => {
//     updateWidth()
//     window.addEventListener('resize', updateWidth)

//     return () => {
//       window.removeEventListener('resize', updateWidth)
//     }
//   }, [])

//   return width
// }

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  // const width = useWidth()

  const siteTitle = data.site.siteMetadata.title
  const desc = data.site.siteMetadata.descriptionExtended.replace(
    /\n|\s+/g,
    ' '
  )
  const posts = data.allMarkdownRemark.edges.map(({ node }) => node)
  const sections: IndexSectionProps[] = [
    {
      title: 'Travel',
      text: `As a child of Japanese parents growing up in the United States,
        multiculturalism has played a large role in shaping who I am.
        
        To this day, I am fascinated by unfamiliar cultures and enjoy
        witnessing the effects that these cultures have in shaping the
        lived experiences of people around the globe.`,
      imageTitle: 'Larung Gar',
      image: getImage(data.travelImage),
      isRight: false
    },
    {
      title: 'Computer Science',
      text: `My first foray into computer science was in 4th grade,
        when I started programming for Lego robotics projects in a
        language called NQC.

        Although mathematics captured my attention in the years that
        followed, I found myself pulled back to the discipline and have
        been pursuing a degree in computer science for the past three
        years.`,
      imageTitle: 'Coding on a MacBook Pro',
      image: getImage(data.codingImage),
      isRight: true
    }
  ]
  const travelSection = sections[0]
  const codingSection = sections[1]

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={desc} />

        {/* Metadata for Open Graph */}
        <meta property="og:title" content={siteTitle} />
        <meta property="og:description" content={desc} />
      </Helmet>
      <div style={{ paddingTop: '80px' }}>
        <div
          style={{
            maxWidth: '52rem',
            margin: '0 auto 160px auto',
            padding: '0 16px'
          }}
        >
          <h1 className={styles.headerText}>
            {'Hi there! '}
            <MobileLineBreak />
            {"I'm Ryosuke Minami."}
          </h1>

          <SvgLine length={120} />

          <h2>
            {"I'm currently studying computer science and philosophy."}
            <br />I spend my days{' '}
            <HighlightedLink to={'/books'}>reading</HighlightedLink>
            {', '}
            <HighlightedLink to={'/projects'}>coding</HighlightedLink>
            {', and '}
            <HighlightedLink to={'/travel'}>traveling</HighlightedLink>
            {' whenever I have the chance.'}
          </h2>
        </div>

        {/* Mobile version */}
        <MobileGrid>
          <div className={styles.imageWrapper}>
            <StaticImage
              alt={travelSection.imageTitle}
              src="../assets/images/larung-gar.jpg"
              layout="fullWidth"
              className={styles.indexSectionImage}
              key={`${titleToKey(travelSection.imageTitle)}-mobile`}
            />
          </div>
          <IndexSectionText
            title={travelSection.title}
            text={travelSection.text}
          />
          <div className={styles.imageWrapper}>
            <StaticImage
              alt={codingSection.imageTitle}
              src="../assets/images/coding.jpg"
              layout="fullWidth"
              className={styles.indexSectionImage}
              key={`${titleToKey(codingSection.imageTitle)}-mobile`}
            />
          </div>
          <IndexSectionText
            title={codingSection.title}
            text={codingSection.text}
          />
        </MobileGrid>

        {/* Desktop version */}
        <DesktopGrid>
          {sections.map((section: IndexSectionProps) => (
            <IndexSection
              title={section.title}
              text={section.text}
              imageTitle={section.imageTitle}
              image={section.image}
              isRight={section.isRight}
              key={titleToKey(section.title)}
            />
          ))}
        </DesktopGrid>
      </div>
      <div
        style={{
          maxWidth: '1200px',
          margin: '0 auto'
        }}
      >
        <IntroText>Recent blog posts</IntroText>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
            gridColumnGap: '12px',
            padding: '30px 0 0'
          }}
        >
          {posts.map((node: BlogPost['node']) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <div
                key={node.fields.slug}
                style={{
                  maxWidth: '1200px',
                  padding: '20px 0',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              >
                <h3 style={{ marginTop: '6px', marginBottom: '12px' }}>
                  <PostLink to={node.fields.slug}>{title}</PostLink>
                </h3>
                <small>{node.frontmatter.date}</small>
                <p style={{ fontSize: '18px', lineHeight: '28px' }}>
                  {parse(node.excerpt)}
                </p>
                <p>
                  <Link to={node.fields.slug}>Continue reading →</Link>
                </p>
              </div>
            )
          })}
        </div>
        <BlogLinkWrapper>
          <Link to={'/blog'}>See all posts</Link>
        </BlogLinkWrapper>
      </div>
    </Layout>
  )
}

interface IndexPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        descriptionExtended: string
      }
    }
    allMarkdownRemark: {
      edges: BlogPost[]
    }
    travelImage: ImageDataLike
    codingImage: ImageDataLike
  }
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        descriptionExtended
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
          }
        }
      }
    }
    travelImage: file(relativePath: { eq: "images/larung-gar.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP])
      }
    }
    codingImage: file(relativePath: { eq: "images/coding.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP])
      }
    }
  }
`

export default IndexPage
