import React, { ReactNode } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

const HighlightedLinkWrapper = styled(Link)`
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-shadow: none;
  text-decoration: none;
  transition: background-size 0.3s ease;
  background: linear-gradient(to bottom, transparent 60%, #aedefc 0) left
    center/0% 100% no-repeat;

  :hover {
    background-size: 100% 100%;
    text-decoration: none;
  }
`

HighlightedLinkWrapper.defaultProps = {
  color: 'accent'
}

export interface HighlightedLinkProps {
  to: string
  children?: ReactNode
}

const HighlightedLink: React.FC<HighlightedLinkProps> = ({ children, to }) => (
  <HighlightedLinkWrapper to={to}>{children}</HighlightedLinkWrapper>
)

export default HighlightedLink
