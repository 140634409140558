import { Link } from 'gatsby'
import styled from '@emotion/styled'

export default styled(Link)`
  font-family: inherit;
  font-size: 26px;
  box-shadow: none;
  text-decoration: none;
  color: inherit;

  &:hover {
    color: #757575;
    text-decoration: none;
  }

  @media only screen and (max-width: 640px) {
    font-size: 24px;
  }
`
